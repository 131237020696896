<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="add-select">
          <b-form-select v-model="selected" class="main__select" :options="options" />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </div>
        <IndividualBlock v-if="selected" :counterparty-data="counterpartyData" />
        <EntityBlocks v-if="selected === false" :counterparty-data="counterpartyData" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selected: null,
      counterpartyData: null,
      options: [
        { value: null, text: "Выберать лицо", disabled: true },
        { value: true, text: "Физическое лицо" },
        { value: false, text: "Юридическое лицо" },
      ],
    };
  },
  computed: {
    ...mapState(["contragentsList"]),
  },
  components: {
    IndividualBlock: () => ({
      component: import("@/components/add/conterparty/individual"),
    }),
    EntityBlocks: () => ({
      component: import("@/components/add/conterparty/entity"),
    }),
  },
  mounted() {
    if (this.contragentsList && this.$route.query.id) {
      this.counterpartyData = this.contragentsList.find((e) => e.id === +this.$route.query.id);
      this.counterpartyData.type === "individual"
        ? (this.selected = true)
        : (this.selected = false);
    }
  },
  created() {
    this.$store.dispatch("getContragentsList");
  },
};
</script>

<style scoped>
.add-select {
  width: 30%;
  margin: 0;
  position: relative;
  background: #fff;
  margin-bottom: 20px;
}
</style>
